<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('school')"
                        :isNewButton="checkPermission('school_store')"
                        :isColumns="true"
                        @new-button-click="add"
                        @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <HeaderMobile :title="$t('school')"
                          :isNewButton="checkPermission('school_store')"
                          :isColumns="true"
                          @new-button-click="add"
                          @filter-div-status="datatable.filterStatus = $event">
                <template v-slot:columns>
                    <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                        <b-form-checkbox
                            :id="'checkbox-'+key"
                            :name="'checkbox-'+key"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ br2space(column.label) }}
                        </b-form-checkbox>
                    </div>
                </template>
            </HeaderMobile>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md" :on-hide-only-x="true"
                         @bHideModalHeaderClose="hideModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? "add" : "edit") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('code')">
                                                <b-form-input v-model="form.code">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input v-model="form.name">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="country_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('country_id')">
                                                <country-selectbox v-model="form.country_id">
                                                </country-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="city_id" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('city_id')">
                                                <city-selectbox v-model="form.city_id">
                                                </city-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="address" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input v-model="form.address">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t("save") }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import SchoolService from "@/services/SchoolService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CitySelectbox from "@/components/interactive-fields/CitySelectbox.vue";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox.vue";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        CitySelectbox,
        CountrySelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("school"),
        };
    },
    data() {
        return {
            id: 0,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "school_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "school_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: ("id"),
                        sortable: true,
                    },
                    {
                        label: this.$t("code"),
                        field: ("code"),
                        sortable: false,
                    },
                    {
                        label: this.$t("name"),
                        field: ("name"),
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return SchoolService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .catch(err=>this.showErrors(err))
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData() {
            if (this.id > 0) {
                SchoolService.get(this.id)
                    .then((response) => {
                        this.$refs.storeForm.reset();
                        this.form = response.data.data;
                        this.$refs.modal.$refs.commonModal.show();
                    })
                    .catch((error) => {
                        if (error.data.message) {
                            this.showErrors(error, this.$refs.storeForm)
                        }
                    });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                if (this.id == 0) {
                    SchoolService.store(this.form)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.successProcess()
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.storeForm)
                        })
                } else {
                    SchoolService.update(this.id, this.form)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.successProcess()
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.storeForm)
                        })
                }
            }
        },
        delete(id) {
            this.deleteModal(() => {
                SchoolService.deleteSchool(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },
        hideModal() {
            this.$refs.modal.$refs.commonModal.hide();
        },
        successProcess() {
            this.hideModal();
        }
    },
};
</script>

